<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3 "></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">Welcome to {{ appName }}</p>
                  <p class="mb-2">
                    <span v-if="wasLoggedout" class="ba-success--text">You were successfully logged out.</span>
                    <span v-else>Please sign-in to your account</span>
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text v-if="showForm">
                  <v-form @submit.prevent="login">
                    <v-text-field
                      v-model="email"
                      :error-messages="getFieldErrors('email')"
                      :error="fieldHasErrors('email')"
                      outlined
                      label="Email"
                      placeholder="email"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      :error-messages="getFieldErrors('password')"
                      :error="fieldHasErrors('password')"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">
                      <!-- forget link -->
                      <a class="ms-3" href="https://my.betteragency.io/users/forget-password"> Forgot Password? </a>
                    </div>

                    <v-btn block color="primary" type="submit" class="mt-6">
                      Login
                    </v-btn>
                  </v-form>
                </v-card-text>
                <v-card-text v-if="showForm === false && loading.permissionCheck === false">
                  <v-alert prominent type="error" class="text-center">
                    Login currently disabled
                  </v-alert>
                </v-card-text>
                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    New on our platform?
                  </p>
                  <a href="https://betteragency.io"> Create an account </a>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import HasFormErrors from '@/config/mixins/HasFormErrors'
import LDClient from 'launchdarkly-js-client-sdk'
import { mapGetters } from 'vuex'
import * as EVENTS from '@/config/globals/trackingEvents'

export default {
  mixins: [HasFormErrors],
  data() {
    return {
      isPasswordVisible: false,
      email: '',
      password: '',
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      showForm: false,
      loading: {
        permissionCheck: true,
      },
    }
  },
  methods: {
    login() {
      this.clearFormException()
      this.$debug.requests('Starting authenticate request.')
      this.$store
        .dispatch('authenticate', {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$debug.requests('Authentication request was successful. Sending user to dashboard.')
          this.$tracking.event(EVENTS.LOGIN)

          this.$router.push({ name: 'commissions' })
        })
        .catch(validationException => {
          this.setFormException(validationException)
          this.$debug.errors('Authentication request failed: ', validationException.response)
        })
    },
  },
  mounted() {
    this.showForm = true

    /*
    if (this.$route.query['force-show'] === 'true') {
      this.showForm = true;
      this.loading.permissionCheck = false;
    } else {
      let featureFlag = LDClient.initialize(process.env.VUE_APP_LAUNCH_DARKLY_KEY, {"key": 'system'});

      featureFlag.on('ready', () => {
        if (featureFlag.variation('spa-login', false)) {
          this.showForm = true;
          this.loading.permissionCheck = false;
        }
      });
    }
        */
  },
  computed: {
    wasLoggedout() {
      return this.$route.query.loggedOut === 'true'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
